import { InferType, array, bool, mixed, number, object, string } from 'yup'
import i18n from '@src/services/i18n'
import { AddressSchema, FullAddressSchema } from './AddressSchema'
import {
  Constants,
  EApplicantType,
  EBeneficiaryType,
  ECreditApplicationStatus,
  EFinancingProgram,
  EGender,
  EHomeStatus,
  EJobType,
  ELanguage,
  ELanguageList,
  EOriginSystemId,
  EOtherIncomeType,
  EPaymentPlan,
  EProvinceList,
  EServiceCategory,
} from './Constants'
import { Contract } from './Contract'
import { CreditDecision, NormCreditDecision } from './CreditDecision'
import { ElectronicSignature } from './ElectronicSignature'
import { FilteredWorksheet } from './FilteredWorksheet'
import { PrequalificationDecision } from './PrequalificationDecision'
import { RequiredDocument } from './RequiredDocument'
import yupExtInt from './common/SchemaTypes'

export const expensesSchema = object({
  financingProgramId: mixed<EFinancingProgram>()
    .nullable()
    .default('' as EFinancingProgram),
  monthlyInstallmentLoanPayment: number().positive().nullable(),
  monthlyStudentLoanPayment: number().positive().nullable(),
  monthlyLineOfCreditPayment: number().positive().nullable(),
  monthlyCreditCardsPayment: number().positive().nullable(),
  monthlyPaydayLoanPayment: number().positive().nullable(),
  monthlyPersonalLoanPayment: number().positive().nullable(),
  monthlyOtherLoanPayment: number().positive().nullable(),
  monthlyOtherExpensePayment: number().positive().nullable(),
  otherExpensePaymentDescription: string()
    .nullable()
    .max(50)
    .when('monthlyOtherExpensePayment', (val) => {
      return val && (val as unknown as number) > 0
        ? string().required('common.errors.required').default('')
        : string().notRequired()
    }),
  homeFeeTypeId: string().default('').required('common.errors.required').nullable(),
  rentMonthly: yupExtInt.double,
  mortgageMonthly: yupExtInt.double,
  housingMonthly: yupExtInt.double.when('homeFeeTypeId', (value: EHomeStatus[]) => {
    return value?.length && value[0] === EHomeStatus.Renter
      ? yupExtInt.double.required('common.errors.required').min(1)
      : yupExtInt.double.notRequired().positive()
  }),
  houseMarketValue: yupExtInt.double.min(1, 'common.errors.positive').notRequired().nullable(),
  landlord: string().nullable().notRequired().default(''),
  monthlyOwnerInsuranceFee: yupExtInt.double.min(0),
  monthlyMortgageInsuranceFee: yupExtInt.double.min(0),
  monthlyMunicipalAndSchoolTaxPayment: yupExtInt.double.min(0),
  monthlyCondominiumFee: yupExtInt.double.min(0),
  monthlyPublicServiceFee: yupExtInt.double.min(0),
  monthlyRrspRefund: yupExtInt.double.min(0),
  monthlyOtherHomeFee: yupExtInt.double.min(0),
  isCompleted: bool().default(false).nullable(),
})

export type ApplicantExpenses = InferType<typeof expensesSchema>

export const ExpensesSchema = expensesSchema.when((value: ApplicantExpenses[], schema) => {
  if (value[0]?.houseMarketValue || value[0]?.housingMonthly) {
    schema.fields.homeFeeTypeId = string().required('common.errors.required')
  }
  return schema
})

export const ApplicantAddressSchema = AddressSchema.shape({
  years: yupExtInt.integer.min(0).notRequired().nullable(),
  months: yupExtInt.integer.when('years', (value) => {
    return value && value[0] > 0
      ? yupExtInt.integer.notRequired().positive()
      : yupExtInt.integer.required('common.errors.required').positive()
  }),
})

export const JobSchema = object({
  id: string().default(''),
  employerName: string().required('common.errors.required').default(''),
  jobTitle: string().required('common.errors.required').default(''),
  jobType: yupExtInt.numberEnum<EJobType>().required('common.errors.required').default(null),
  annualSalary: yupExtInt.integer.required('common.errors.required').min(1, 'common.errors.positive').default(null),
  employerPhone: string().IsValidCanadianPhone().nullable(),
  employerPhoneExt: string().IsValidPhoneExtension().nullable(),
  address: AddressSchema.nullable(),
  years: yupExtInt.integer.min(0).notRequired().nullable(),
  months: yupExtInt.integer.when('years', (value) => {
    return value && value[0] > 0
      ? yupExtInt.integer.notRequired().positive()
      : yupExtInt.integer.required('common.errors.required').min(1)
  }),
  verificationContactName: string().nullable(),
  verificationContactRole: string().nullable(),
  verificationContactPhone: string().nullable(),
})
export type ApplicantJob = InferType<typeof JobSchema>

export const OtherIncomeSchema = object({
  id: string().default(''),
  typeId: yupExtInt.integer.required('common.errors.required'),
  description: string()
    .nullable()
    .when('typeId', (value) => {
      return value[0] === EOtherIncomeType.other ? string().required('common.errors.required') : string().notRequired()
    }),
  annualAmount: yupExtInt.integer.required('common.errors.required').positive(),
})
export type ApplicantOtherIncome = InferType<typeof OtherIncomeSchema>

export type IncomeVerification = {
  thirdPartyFlowCompleted: boolean
  incomeMismatch: boolean
  nameMismatch: boolean
  allIncomeTasksConfirmed: boolean
  hasIncomeServiceAdjustment: boolean
  thirdPartyIncome: number
  declaredIncome: number
  requiredExternalStep: RequiredExternalStep
}

export type RequiredExternalStep = {
  applicantType: string
  status: string
}

const draftApplicantSchema = {
  id: string().nullable().default(null), // le default null est nécessaire pour le createEntityAdapter
  middleName: string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .default('')
    .nullable(),
  relationWithApplicant: string().when('isPrimaryApplicant', (value) => {
    return !value[0]
      ? string().nullable().required('common.errors.required').default('')
      : string().notRequired().nullable().default('')
  }),
  firstName: string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .required('common.errors.required')
    .default(''),
  lastName: string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .required('common.errors.required')
    .default(''),
  birthDate: string().default('').isValidDate().validateAgeRequirement(),
  sin: string().SinType().default(''),
  languageId: yupExtInt
    .numberEnum<ELanguage>(ELanguageList)
    .nonNullable()
    .default(() => (i18n.resolvedLanguage === 'fr' ? ELanguage.French : ELanguage.English))
    .required('common.errors.required'),
  genderId: yupExtInt.numberEnum<EGender>().nullable().default(null),
  maritalStatusId: yupExtInt.integer.nullable().default(null),
  homePhone: string().default('').IsValidCanadianPhone().nullable(),
  cellPhone: string().default('').IsValidCanadianPhone().nullable(),
  email: string().notRequired().email('common.errors.invalidEmail').default(''),
  // Incomes
  currentJobs: array(JobSchema).default([]),
  previousJobs: array(JobSchema).default([]),
  otherIncomes: array(OtherIncomeSchema).default([]),
  // address
  currentAddress: ApplicantAddressSchema,
  expenses: ExpensesSchema.notRequired().default(ExpensesSchema.getDefault()),
  isPrimaryApplicant: bool().required('common.errors.required').default(true),
  stateIso: string().oneOf(EProvinceList),
}

export const DraftApplicantSchema = object(draftApplicantSchema)

const fullApplicantSchema = { ...draftApplicantSchema }
fullApplicantSchema.birthDate = fullApplicantSchema.birthDate
  .required('common.errors.required')
  .isValidDate()
  .validateAgeRequirement()
fullApplicantSchema.genderId = fullApplicantSchema.genderId.required('common.errors.required')
fullApplicantSchema.languageId = fullApplicantSchema.languageId.required('common.errors.required')
fullApplicantSchema.maritalStatusId = fullApplicantSchema.maritalStatusId.required('common.errors.required')
fullApplicantSchema.cellPhone = fullApplicantSchema.cellPhone.IsValidCanadianPhone().required('common.errors.required')
fullApplicantSchema.email = fullApplicantSchema.email.required('common.errors.required')
fullApplicantSchema.currentAddress = FullAddressSchema

export const FullApplicantSchema = object(fullApplicantSchema)

export type ApplicantDto = InferType<typeof DraftApplicantSchema> & {
  incomeVerification?: IncomeVerification
  hardHitReportReceivedOn?: Date
}

const draftCreditApplicationSchema = {
  id: string().default(''), // le default null est nécessaire pour le createEntityAdapter
  financingProgramId: mixed<EFinancingProgram>().default(EFinancingProgram.Personal),
  loanPurposeId: string().required('common.errors.required').default(''),
  requestedLoanAmount: yupExtInt.double
    .required('common.errors.required')
    .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
    .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount')
    .default(null),
  merchantId: string().required('common.errors.required').default(''),
  status: mixed<ECreditApplicationStatus>().required('common.errors.required').default(ECreditApplicationStatus.Draft),
  applicant: DraftApplicantSchema.required('common.errors.required'),
  coApplicant: DraftApplicantSchema.nullable().default(null),
  editLocked: bool().default(false),
  correspondanceLanguageId: number(),
  versionTag: string(),
  beneficiaryTypeId: yupExtInt.integer
    .required('common.errors.required')
    .oneOf([EBeneficiaryType.NotSet, EBeneficiaryType.Applicant, EBeneficiaryType.Coapplicant, EBeneficiaryType.Other])
    .default(EBeneficiaryType.Applicant),
  otherBeneficiaryFirstName: string()
    .trim()
    .when('loanPurposeId', (loanPurposeId, schema) => {
      if (loanPurposeId[0] === EServiceCategory.Veterinary || loanPurposeId[0] === EServiceCategory.GoodsAndServices) {
        return schema.notRequired().nullable()
      }
      return schema.when('beneficiaryTypeId', (value) => {
        return value[0] === EBeneficiaryType.Other
          ? string().required('common.errors.required')
          : string().notRequired().nullable()
      })
    }),

  otherBeneficiaryLastName: string()
    .trim()
    .when('loanPurposeId', (loanPurposeId, schema) => {
      if (loanPurposeId[0] === EServiceCategory.GoodsAndServices) {
        return schema.notRequired().nullable()
      }
      return schema.when('beneficiaryTypeId', (value) => {
        return value[0] === EBeneficiaryType.Other
          ? string().required('common.errors.required')
          : string().notRequired().nullable()
      })
    }),
  originSystemId: mixed<EOriginSystemId>()
    .required('common.errors.required')
    .default(EOriginSystemId.MerchantDashboard),
  consentSoftHit: bool().required('common.errors.required').default(false),
  consentHardHit: bool().required('common.errors.required').default(false),
}

export const DraftCreditApplicationSchema = object(draftCreditApplicationSchema).test(
  'test-coappSameMobileError',
  'common.errors.coappSameMobileError',
  function distinctCellphoneTest(value) {
    const { createError } = this
    if (value?.coApplicant !== null) {
      const coappCellPhone = value.coApplicant.cellPhone
      const appCellphone = value.applicant.cellPhone
      if (appCellphone && coappCellPhone) {
        return (
          appCellphone !== coappCellPhone ||
          createError({ path: 'coApplicant.cellPhone', message: 'common.errors.coappSameMobileError' })
        )
      }
    }
    return true
  },
)

export type DraftCreditApplicationDto = InferType<typeof DraftCreditApplicationSchema>

export const FullCreditApplicationSchema = DraftCreditApplicationSchema.shape({
  merchantId: draftCreditApplicationSchema.merchantId.required('common.errors.required'),
  applicant: FullApplicantSchema.required('common.errors.required'),
  coApplicant: FullApplicantSchema.nullable().default(null),
})

type EditCreditApplicationDto = InferType<typeof FullCreditApplicationSchema>

export type CreditApplication = EditCreditApplicationDto & {
  applicant: ApplicantDto
  coApplicant: ApplicantDto | null
  currentRevisionId: string
  referenceNumber: number
  parentReferenceNumber: number | null
  parentCreditApplicationId: string | null
  normsCreditDecision: NormCreditDecision | null
  finalCreditDecision: CreditDecision
  createdOn: Date
  updatedOn: Date
  expiresOn: Date
  deliveryOn: string
  createdByUserFullName: string
  correspondanceLanguageId: number
  cvtNumber: number | null
  loanId: number | null
  loanPurposeId: EServiceCategory
  prequalificationDecision: PrequalificationDecision
  merchantPaymentPlanId: EPaymentPlan
  allIncomesConfirmed: boolean
  requiredDocuments: RequiredDocument[]
  loanCreated: boolean
  beneficiaryTypeId: number
  otherBeneficiaryFirstName: string
  otherBeneficiaryLastName: string
  consentSoftHit: boolean
  consentHardHit: boolean
  electronicSignatures: ElectronicSignature[]
  financingProgramId: EFinancingProgram
  worksheet: FilteredWorksheet
  contract: Contract
  hasTasksBeforeCvt: boolean
  availableCreditAmount: number
}

const editApplicantContactInfoDtoSchema = {
  creditApplicationId: string().nullable().default(null),
  cellPhone: string().default('').IsValidCanadianPhone().required('common.errors.required'),
  email: string().notRequired().email().default('').required('common.errors.required'),
  languageId: yupExtInt.numberEnum<ELanguage>().nonNullable().default(null).required('common.errors.required'),
  applicantType: mixed<EApplicantType>().nullable().default(null),
}

export const EditApplicantContactInfoDtoSchema = object(editApplicantContactInfoDtoSchema)

export type EditApplicantContactInfoDto = InferType<typeof EditApplicantContactInfoDtoSchema>
